// extracted by mini-css-extract-plugin
export const header = "navMenu-module--header--1SXp9";
export const headerWrapper = "navMenu-module--headerWrapper--3lzC5";
export const headerBox = "navMenu-module--headerBox--2T5Y2";
export const row = "navMenu-module--row--2Zs14";
export const logoWrapper = "navMenu-module--logoWrapper--2Vf5d";
export const logo = "navMenu-module--logo--3l2wT";
export const logoImage = "navMenu-module--logoImage--Z1WYd";
export const housEdge = "navMenu-module--housEdge--11BJF";
export const menuWrapper = "navMenu-module--menuWrapper--1wgnj";
export const burgerMenu = "navMenu-module--burgerMenu--2V3Nq";
export const burgerInner = "navMenu-module--burgerInner--19Pd9";
export const menu = "navMenu-module--menu--10KKy";
export const menuItem = "navMenu-module--menuItem--1Sb2h";
export const active = "navMenu-module--active--1X65H";