// extracted by mini-css-extract-plugin
export const callToAction = "ctaBottom-module--callToAction--aQP9t";
export const featuredArticles = "ctaBottom-module--featuredArticles--3Z0Iu";
export const wrapper = "ctaBottom-module--wrapper--1-A3S";
export const mainTitle = "ctaBottom-module--mainTitle--1lprE";
export const boxed = "ctaBottom-module--boxed--3oAau";
export const grid = "ctaBottom-module--grid--3WDZh";
export const article = "ctaBottom-module--article--1Vmkb";
export const articleImage = "ctaBottom-module--articleImage--VuR1N";
export const image = "ctaBottom-module--image--1shWD";
export const content = "ctaBottom-module--content--2I5ET";
export const meta = "ctaBottom-module--meta--3vwvk";
export const category = "ctaBottom-module--category--1mKyJ";
export const date = "ctaBottom-module--date--3Nblv";
export const articleTitle = "ctaBottom-module--articleTitle--3lh-i";
export const titleLink = "ctaBottom-module--titleLink--2gU0L";
export const excerpt = "ctaBottom-module--excerpt--c0U2X";