// extracted by mini-css-extract-plugin
export const container = "copyright-module--container--323P5";
export const copyright = "copyright-module--copyright--1I4Pv";
export const copyrightBox = "copyright-module--copyrightBox--1jXuH";
export const row = "copyright-module--row--1hXyS";
export const copyrightWrapper = "copyright-module--copyrightWrapper--3qwkY";
export const copyrightLogo = "copyright-module--copyrightLogo--37qPb";
export const copyrightText = "copyright-module--copyrightText--251yF";
export const fooMenuWrapper = "copyright-module--fooMenuWrapper--Z8afU";
export const fooMenu = "copyright-module--fooMenu--3oWgJ";
export const menuItem = "copyright-module--menuItem--JyohF";